'use client'
import React, { useEffect, useMemo, useState } from 'react'
import { AutoComplete, DatePicker, Input, Radio, Space, message } from 'antd'
import { DatePickerWithToday } from '@bossjob/ui'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { Global, css } from '@emotion/react'

import { removeEmptyOrNullValues } from 'helpers/formatter'
import { addUserEducationService } from 'store/services/users/addUserEducation'
import { updateUserEducationService } from 'store/services/users/updateUserEducation'
import { searchFieldStudySuggestion } from 'store/services/config/searchFieldStudySuggestion'

import SimpleSteps from '../Step/Simple'
import { useAttachedResumeContext } from '../AttachedResume/context'
import SchoolPopover from './SchoolPopover'
import LoadingButton from '../Common/LoadingButton'

import styles from './index.module.scss'
import { addEventTag, isMobileDevice } from 'helpers/utilities'
import { getCountryKey, getLang, getServerLanguageCode } from 'helpers/country'
import { isChatProcess } from '../utils'
import { Button } from 'app/(new-pages)/components/Button/pc/index.pc'
import { BackIcon } from '../Common/icons'
import { CustomRadio } from '../CustomRadio'
import TextEditor from 'components/TextEditor'
const { TextArea } = Input

const getStudyIncreaseStep = (eduId: number) => {
  let eduList = [
    {
      key: 'high_school_senior_high_school',
      value: 6,
      step: [15, 3]
    },
    {
      key: 'diploma',
      value: 1,
      step: [18, 2]
    },
    { key: 'bachelor', value: 2, step: [18, 4] },
    { key: 'master', value: 3, step: [22, 2] },
    { key: 'doctor', value: 4, step: [24, 4] }
  ]
  return eduList.find((item) => item.value === eduId)?.step || [15, 3]
}

const formatFieldOfStudyList = (list) => {
  return list.map((item, index) => {
    return {
      key: index,
      label: item,
      value: item
    }
  })
}

const EducationExperience = (props: {
  lang: any
  config: any
  onCancel: () => void
  onOk: (value: any) => void
  schoolList: any[]
  userDetail: any
  getUserInfo: () => void
}) => {
  const { onCancel, onOk, config, schoolList, userDetail = {}, getUserInfo, lang = {} } = props
  const [loading, setLoading] = useState(false)
  const [fieldOfStudyOptions, setFieldOfStudyOptions] = useState([])
  const [loadingFieldOfStudy, setLoadingFieldOfStudy] = useState(false)
  const country_key = getCountryKey()
  const langKey = getLang()
  const langCode = getServerLanguageCode(langKey) || 'en'
  const { educations = [] } = userDetail
  const profile = lang?.profile || {}
  const comm = lang?.comm || {}

  const birthdate = userDetail.birthdate ? new Date(userDetail.birthdate) : null
  // console.log('profile::', profile)

  const chatProcess = isChatProcess()
  const isMobile = isMobileDevice()

  const degrees = useMemo(() => {
    return config?.degrees?.filter((e) => e.id !== 5)
  }, [config])

  const { eduData, setEduData } = useAttachedResumeContext()

  // console.log('eduData', eduData)

  const disabledSubmit = useMemo(() => {
    const checkDate = !eduData.startDate || (!eduData.endDate && !eduData.isToday)
    const desc = stripHtmlTags(eduData.description)
    const checkDescription = desc && desc?.length < 10
    if (checkDescription) return true
    return !eduData?.degree || !eduData?.schoolName || !eduData?.fieldOfStudy || checkDate
  }, [eduData])

  const fetchFieldOfStudy = () => {
    setLoadingFieldOfStudy(true)
    searchFieldStudySuggestion({
      field_study: '',
      language_code: langCode || 'en',
      country_key: country_key || 'ph'
    })
      .then((res) => {
        if (res?.data?.data?.length) {
          setFieldOfStudyOptions(formatFieldOfStudyList(res?.data?.data))
        } else {
          setFieldOfStudyOptions([])
        }
      })
      .finally(() => setLoadingFieldOfStudy(false))
  }

  useEffect(() => {
    fetchFieldOfStudy()
  }, [])

  const handleEndTime = (date, dateString, isToday) => {
    setEduData({ ...eduData, endDate: date, isToday: isToday })
  }

  const handleStartTime = (date, dateString) => {
    setEduData({ ...eduData, startDate: date, endDate: null })
  }

  const handleOk = () => {
    const educationData = {
      school: eduData.schoolName,
      is_currently_studying: eduData.isToday,
      study_period_from: `${eduData.startDate?.format('YYYY-MM')}-01`,
      study_period_to: eduData.isToday ? null : `${eduData.endDate?.format('YYYY-MM')}-01`,
      field_of_study: eduData.fieldOfStudy,
      degree_id: eduData.degree,
      description: eduData.description
    }

    onSubmit(educationData)
  }

  const handleCancel = () => {
    onCancel()
  }

  const onSubmit = (educationData) => {
    setLoading(true)
    addEventTag(
      chatProcess
        ? 'new_user_chat_resume_education_fill_next'
        : 'new_user_complete_resume_education_fill_next'
    )
    if (educations?.length) {
      const id = educations[0].id
      const educationPayload = {
        educationId: id,
        educationData: educationData // removeEmptyOrNullValues(educationData)
      }
      updateUserEducationService(educationPayload)
        .then((res) => {
          if (res.data) {
            getUserInfo && getUserInfo()
            onOk(false)
          }
        })
        .finally(() => setLoading(false))
    } else {
      const educationPayload = {
        educationData: removeEmptyOrNullValues(educationData)
      }
      addUserEducationService(educationPayload)
        .then((res) => {
          if (res.data) {
            getUserInfo && getUserInfo()
            onOk(false)
          }
        })
        .finally(() => setLoading(false))
    }
  }

  const disabledDate = (current) => {
    return current && current < dayjs(eduData.startDate).subtract(1, 'day').endOf('day')
  }

  const disabledDateStartDate = (current) => {
    return current && current > dayjs()
  }

  function stripHtmlTags(html) {
    return html?.replace?.(/<[^>]+>/g, '')
  }

  const checkDescription = useMemo(() => {
    const text = stripHtmlTags(eduData.description)
    if (!text) return false
    if (text?.length < 10) return true
    if (text?.length > 4000) return true
    return false
  }, [eduData.description])

  return (
    <div className={styles.eduExp + ' ' + 'yoloJobseekerCompletedSelect'}>
      <Global styles={GlobalStyles} />
      <div className={styles.eduExp_container}>
        {isMobile ? (
          <EducationIconH5 className={styles.eduExp_icon} />
        ) : (
          <EducationIcon className={styles.eduExp_icon} />
        )}

        <div className={styles.eduExp_title}>
          <p className={styles.eduExp_subtitle}>{profile.eduExp?.title}</p>
          <SimpleSteps className={styles.eduExp_steps} currentStep={1} totalSteps={2} />
        </div>

        <div className={styles.eduExp_content}>
          <div className={styles.eduExp_item}>
            <p className={styles.eduExp_label}>{profile.eduExp?.degree}</p>
            <div className={styles.eduExp_radio}>
              {/* <Radio.Group
                value={eduData.degree}
                onChange={(e) => setEduData({ ...eduData, degree: e.target.value })}
              >
                <Space direction='vertical'>
                  {degrees?.map((item, index) => {
                    return (
                      <Radio value={item.id} key={index}>
                        {item.value}
                      </Radio>
                    )
                  })}
                </Space>
              </Radio.Group> */}

              <div className={styles.eduExp_radioGroup}>
                {degrees.map((item) => (
                  <CustomRadio
                    checked={item.id == eduData.degree}
                    label={item.value}
                    value={item.id}
                    key={item.id}
                    onChange={(e) => {
                      const degreeId = e.target.value
                      const { endDate, startDate } = eduData

                      const form = { degree: degreeId }

                      if (
                        // !endDate && !startDate &&
                        birthdate
                      ) {
                        let [from, to] = getStudyIncreaseStep(Number(degreeId))

                        let base = dayjs(birthdate)
                        let now = dayjs()
                        let start = base.add(from, 'year')
                        let end = base.add(from + to, 'year')

                        if (end.isAfter(now)) {
                          end = undefined
                        }
                        if (start.isAfter(now)) {
                          start = undefined
                        }

                        Object.assign(form, { endDate: end, startDate: start })
                      }

                      setEduData({ ...eduData, ...form })
                    }}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className={styles.eduExp_item}>
            <p className={styles.eduExp_label}>{profile.eduExp?.schoolName}</p>
            <div className={styles.eduExp_input}>
              <SchoolPopover
                schoolList={schoolList}
                onChange={(value) => setEduData({ ...eduData, schoolName: value })}
                value={eduData.schoolName}
                placeholder={profile.eduExp?.placeholder}
              />
            </div>
          </div>

          <div className={styles.eduExp_item}>
            <p className={styles.eduExp_label}>{profile.eduExp?.fieldOfStudy}</p>
            <div className={styles.eduExp_input}>
              <AutoComplete
                popupClassName='custom-autocomplete-dropdown-wrapper'
                style={{ width: '100%', height: 42 }}
                placeholder={profile.eduExp?.fieldOfStudyPlaceholder}
                notFoundContent={loadingFieldOfStudy ? comm.loading + '...' : null}
                options={fieldOfStudyOptions}
                value={eduData.fieldOfStudy}
                onChange={(value) => {
                  setEduData({ ...eduData, fieldOfStudy: value })
                }}
                filterOption={(value, option) => {
                  if (option?.value?.toLowerCase().indexOf(value?.toLowerCase()) === -1) {
                    return false
                  }
                  return true
                }}
              />
            </div>
          </div>

          <div className={styles.eduExp_item}>
            <p className={styles.eduExp_label}>{profile.eduExp?.studyPeriod}</p>
            <div className={styles.eduExp_date}>
              <DatePicker
                style={{ width: '50%', height: 42 }}
                placeholder={profile.eduExp?.startDate}
                picker='month'
                onChange={handleStartTime}
                value={eduData.startDate}
                inputReadOnly={true}
                disabledDate={disabledDateStartDate}
              />
              <DatePickerWithToday
                style={{ width: '50%', height: 42 }}
                allowClear={true}
                todayText={profile?.today}
                picker='month'
                value={eduData.endDate}
                isToday={eduData.isToday}
                disabledDate={disabledDate}
                placeholder={profile.eduExp?.endDate}
                onChange={handleEndTime}
              />
            </div>
          </div>

          <div className={styles.eduExp_item}>
            <p className={styles.eduExp_label}>
              {profile.eduExp?.descLabel}{' '}
              <span className={styles.eduExp_optional}>({profile?.optional})</span>
            </p>
            <div className={styles.eduExp_input}>
              <TextEditor
                value={eduData.description}
                placeholder={profile.eduExp?.description}
                onChange={(value) => {
                  setEduData({ ...eduData, description: value || '' })
                }}
              />
              {/* <TextArea
                rows={3}
                minLength={10}
                maxLength={4000}
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder={profile.eduExp?.description}
                value={eduData.description}
                status={checkDescription ? 'error' : undefined}
                onChange={(e) => setEduData({ ...eduData, description: e.target.value })}
              /> */}
            </div>
            {checkDescription && (
              <p className={styles.eduExp_error}>{profile.eduExp?.descriptionError}</p>
            )}
          </div>
        </div>

        <div className={styles.eduExp_footer}>
          {/* <button
            className={classNames(styles.eduExp_back, styles.eduExp_button)}
            onClick={handleCancel}
          >
            {profile.back}
          </button> */}
          <div className={styles.eduExp_backBtn} onClick={handleCancel}>
            <BackIcon />
            {profile.back}
          </div>
          {/* <LoadingButton
            className={classNames(styles.eduExp_continue, styles.eduExp_button)}
            loading={loading}
            disabledSubmit={disabledSubmit || loading}
            onSubmit={handleOk}
            text={profile.continue}
          /> */}
          <Button
            loading={loading}
            disabled={disabledSubmit || loading}
            onClick={handleOk}
            variant='primaryBtn'
            btnSize='size44'
          >
            {profile.continue}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default EducationExperience

const EducationIconH5 = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} fill='none' {...props}>
    <path
      fill='#121212'
      d='M18.981 3.333a6.667 6.667 0 0 0-5.962 0L.737 9.473a1.333 1.333 0 0 0 0 2.386l3.93 1.964v10.182c0 1.698 1.082 3.251 2.766 3.75 2.094.618 5.762 1.578 8.567 1.578 2.805 0 6.473-.96 8.567-1.579 1.684-.498 2.766-2.05 2.766-3.749V13.823l3.93-1.964a1.333 1.333 0 0 0 0-2.386l-12.282-6.14Zm-4.77 2.385a4 4 0 0 1 3.578 0l9.896 4.948-9.896 4.948a4 4 0 0 1-3.578 0l-9.896-4.948 9.896-4.948Zm10.456 9.439v8.848c0 .575-.36 1.045-.856 1.192-2.102.622-5.435 1.469-7.811 1.469-2.376 0-5.709-.848-7.811-1.47-.496-.146-.856-.616-.856-1.19v-8.85L13.02 18a6.667 6.667 0 0 0 5.962 0l5.686-2.842Z'
    />
  </svg>
)

const EducationIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={36} height={37} fill='none' {...props}>
    <path
      fill='#000'
      d='M21.354 4.25a7.5 7.5 0 0 0-6.708 0L.829 11.158a1.5 1.5 0 0 0 0 2.683l4.421 2.21v11.455c0 1.91 1.217 3.658 3.112 4.218C10.717 32.42 14.844 33.5 18 33.5c3.155 0 7.283-1.08 9.638-1.776 1.895-.56 3.112-2.307 3.112-4.218V16.052l4.42-2.21a1.5 1.5 0 0 0 0-2.684L21.355 4.25Zm-5.366 2.683a4.5 4.5 0 0 1 4.024 0L31.146 12.5l-11.134 5.567a4.5 4.5 0 0 1-4.024 0L4.854 12.5l11.134-5.567ZM27.75 17.552v9.954c0 .647-.404 1.176-.962 1.34-2.366.7-6.115 1.654-8.788 1.654s-6.422-.954-8.788-1.653c-.558-.165-.962-.694-.962-1.34v-9.955l6.396 3.198a7.5 7.5 0 0 0 6.708 0l6.396-3.198Z'
    />
  </svg>
)

const GlobalStyles = css`
  .custom-autocomplete-dropdown-wrapper.ant-select-dropdown .ant-select-item-option-content {
    overflow: auto;
    white-space: normal;
    text-overflow: inherit;
  }

  .custom-autocomplete-dropdown-wrapper .rc-virtual-list-scrollbar {
    width: 4px !important;
  }

  .custom-autocomplete-dropdown-wrapper .rc-virtual-list-scrollbar-thumb {
    background: #b8b8b8 !important;
  }
`
