import { Modal } from 'antd'

import styles from './index.mobile.module.scss'
import { ButtonWeb3 } from 'app/(new-pages)/(companies)/[lang]/companies/components/Button/index.pc'
import { ChangeLangAndRegion } from 'components/Header/Common/ChangeLangAndRegion'
import { useState } from 'react'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { formatTemplateString } from 'helpers/formatter'
import { getCountry } from 'helpers/country'

export const ChangeCountryConfirmModal = ({ open, onClose }) => {
  const [show, setShow] = useState(false)

  const dic = useSharedData('DICTIONARY') as any

  const title = dic?.profile?.baseProfile?.changeCountryConfirmModal?.title || ''
  const warningTips = dic?.profile?.baseProfile?.changeCountryConfirmModal?.warmTips || ''

  return (
    <>
      <Modal
        footer={<></>}
        closeIcon={<></>}
        className={styles.modalContent}
        open={open}
        onClose={() => {
          onClose()
        }}
        title={null}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
          <div className={styles.header}>{title}</div>
          <div className={styles.confirmTip}>{formatTemplateString(warningTips, getCountry())}</div>
          <div className={styles.footerContent}>
            <ButtonWeb3
              variant='whiteBoxShadowBtn'
              btnSize='size36'
              onClick={(e) => {
                onClose()
              }}
            >
              {dic.comm?.cancel}
              {/* Cancel */}
            </ButtonWeb3>
            <ButtonWeb3
              variant='primaryBtn'
              btnSize='size36'
              onClick={(e) => {
                setShow(true)
                onClose()
              }}
            >
              {dic.comm?.confirm}
            </ButtonWeb3>
          </div>
        </div>
      </Modal>
      <ChangeLangAndRegion
        show={show}
        onAction={({ show }) => {
          setShow(show)
        }}
        showLanguage={false}
      />
    </>
  )
}
